html, body {
	margin: 0;
	padding: 0;
}

.video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
	text-align: center;
}

.video_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.vloader {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.vloader::before {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

:not(.vjs-has-started) .vjs-control-bar {
  display: flex !important;
}

.vjs-big-play-button {
  background-color: rgb(75, 0, 130, 0.7) !important;
}

.vjs-control-bar {
  background-color: rgb(75, 0, 130, 0.7) !important;
}

.vjs-picture-in-picture-control {
  display: none !important;
}

.ldual {
  display: inline-block;
  width: 80px;
  height: 80px;
  vertical-align: middle;
}

.ldual:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: ldual 1.2s linear infinite;
  box-sizing: border-box;
}

@keyframes ldual {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}